import $ from 'jquery';
import 'no-darkreader';
import AOS from 'aos';

$(document).ready(function () {
  AOS.init({
    duration: 600,
    once: true,
  });
});
$(window).on("scroll", function() {
  if($(window).scrollTop() > 50) {
    $(".header-root").addClass("header-root-active");
  } else {
    //remove the background property so it comes transparent again (defined in your css)
    $(".header-root").removeClass("header-root-active");
  }
});
